import * as Apollo from "@apollo/client"
import { TGraphQLClient } from "../types"

import {
  IContentHubType,
  IArticle,
  IAsset,
  IConfig,
  IContentItemList,
  IExpertProfile,
  IExpertProfilePage,
  IHeroImageSectionCollection,
  INavigationBar,
  IOfferBar,
  IPageData,
  IPageSlugWithParentSlug,
  IParagraph,
  ISideNavigation,
  ISlugWithParentSlug,
} from "./contentful-schema"
import {
  GetHeroImageSectionsDocumentContentful,
  IGetHeroImageSectionsQueryVariablesContentful,
  IGetContentItemListQueryVariablesContentful,
  GetContentItemListDocumentContentful,
  GetParagraphDocumentContentful,
  IGetParagraphQueryVariablesContentful,
  IFetchConfigByIdQueryVariablesContentful,
  FetchConfigByIdDocumentContentful,
  GetArticleContentLinksDocumentContentful,
  IGetArticleContentLinksQueryVariablesContentful,
  IFetchStartPageQueryVariablesContentful,
  FetchStartPageDocumentContentful,
  IFetchPageQueryVariablesContentful,
  FetchPageDocumentContentful,
  IGetSideNavigationQueryVariablesContentful,
  GetSideNavigationDocumentContentful,
  IGetPageSlugsQueryVariablesContentful,
  GetPageSlugsDocumentContentful,
  IGetAssetQueryVariablesContentful,
  GetAssetDocumentContentful,
  IFetchPageContentHubByIdQueryVariablesContentful,
  FetchPageContentHubByIdDocumentContentful,
  IFetchExpertProfilePageQueryVariablesContentful,
  FetchExpertProfilePageDocumentContentful,
  IFetchArticleByIdQueryVariablesContentful,
  FetchArticleByIdDocumentContentful,
  IFetchNavigationBarByIdQueryVariablesContentful,
  FetchNavigationBarByIdDocumentContentful,
} from "./contentful-schema.generated"

const defaultOptions = {}

export function useGetHeroImageSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    { heroImageSectionCollection: IHeroImageSectionCollection },
    IGetHeroImageSectionsQueryVariablesContentful
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    { heroImageSectionCollection: IHeroImageSectionCollection },
    IGetHeroImageSectionsQueryVariablesContentful
  >(GetHeroImageSectionsDocumentContentful, options)
}

export function useGetContentItemListQuery(
  baseOptions: Apollo.QueryHookOptions<
    { contentItemList: IContentItemList },
    IGetContentItemListQueryVariablesContentful
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    { contentItemList: IContentItemList },
    IGetContentItemListQueryVariablesContentful
  >(GetContentItemListDocumentContentful, options)
}

export function useGetParagraphQuery(
  baseOptions: Apollo.QueryHookOptions<
    { paragraph: IParagraph },
    IGetParagraphQueryVariablesContentful
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    { paragraph: IParagraph },
    IGetParagraphQueryVariablesContentful
  >(GetParagraphDocumentContentful, options)
}

export function useFetchConfigByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    { config: IConfig },
    IFetchConfigByIdQueryVariablesContentful
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    { config: IConfig },
    IFetchConfigByIdQueryVariablesContentful
  >(FetchConfigByIdDocumentContentful, options)
}

export function useGetArticleContentLinksQuery(
  baseOptions: Apollo.QueryHookOptions<
    { article?: IArticle },
    IGetArticleContentLinksQueryVariablesContentful
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    { article: IArticle },
    IGetArticleContentLinksQueryVariablesContentful
  >(GetArticleContentLinksDocumentContentful, options)
}

export function useGetSideNavigationQuery(
  baseOptions: Apollo.QueryHookOptions<
    {
      sideNavigationCollection?: {
        items: ISideNavigation[]
      }
    },
    IGetSideNavigationQueryVariablesContentful
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    { sideNavigationCollection: { items: ISideNavigation[] } },
    IGetSideNavigationQueryVariablesContentful
  >(GetSideNavigationDocumentContentful, options)
}

export function getPageSlugsQuery(
  apolloClient: TGraphQLClient,
  baseOptions: Apollo.QueryHookOptions<
    {
      slugCollection?: {
        items: ISlugWithParentSlug[]
      }
      pageCollection?: {
        items: IPageSlugWithParentSlug[]
      }
    },
    IGetPageSlugsQueryVariablesContentful
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return apolloClient.query<{
    slugCollection: {
      items: ISlugWithParentSlug[]
    }
    pageCollection: {
      items: IPageSlugWithParentSlug[]
    }
  }>({
    query: GetPageSlugsDocumentContentful,
    variables: options.variables,
  })
}

export function useGetAssetQuery(
  baseOptions: Apollo.QueryHookOptions<
    {
      asset?: IAsset
    },
    IGetAssetQueryVariablesContentful
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<{ asset: IAsset }, IGetAssetQueryVariablesContentful>(
    GetAssetDocumentContentful,
    options
  )
}

export function useFetchStartPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    {
      config: IConfig
      promoOfferBar: IOfferBar
      pageCollection: {
        items: Array<IPageData>
      }
    },
    IFetchStartPageQueryVariablesContentful
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    {
      config: IConfig
      promoOfferBar: IOfferBar
      pageCollection: {
        items: Array<IPageData>
      }
    },
    IFetchStartPageQueryVariablesContentful
  >(FetchStartPageDocumentContentful, options)
}

export function useFetchPageByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    {
      config: IConfig
      promoOfferBar: IOfferBar
      page: IPageData
    },
    IFetchPageQueryVariablesContentful
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    {
      config: IConfig
      promoOfferBar: IOfferBar
      page: IPageData
    },
    IFetchPageQueryVariablesContentful
  >(FetchPageDocumentContentful, options)
}

export function useFetchPageContentHubByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    {
      pageCollection: {
        items: Array<{
          contentHubSectionCollection: {
            items: Array<IContentHubType>
          }
        }>
      }
    },
    IFetchPageContentHubByIdQueryVariablesContentful
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    {
      pageCollection: {
        items: Array<{
          contentHubSectionCollection: {
            items: Array<IContentHubType>
          }
        }>
      }
    },
    IFetchPageContentHubByIdQueryVariablesContentful
  >(FetchPageContentHubByIdDocumentContentful, options)
}

export function useFetchExpertProfilePageQuery(
  baseOptions: Apollo.QueryHookOptions<
    {
      expertProfile: IExpertProfile
      config: IConfig
      promoOfferBar: IOfferBar
      pageCollection: {
        items: Array<IExpertProfilePage>
      }
    },
    IFetchExpertProfilePageQueryVariablesContentful
  >
) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }

  return Apollo.useQuery<
    {
      expertProfile: IExpertProfile
      config: IConfig
      promoOfferBar: IOfferBar
      pageCollection: {
        items: Array<IExpertProfilePage>
      }
    },
    IFetchExpertProfilePageQueryVariablesContentful
  >(FetchExpertProfilePageDocumentContentful, options)
}

export function useFetchArticleByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    { article: IArticle; config: IConfig },
    IFetchArticleByIdQueryVariablesContentful
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    { article: IArticle; config: IConfig },
    IFetchArticleByIdQueryVariablesContentful
  >(FetchArticleByIdDocumentContentful, options)
}

export function useFetchNavigationBarByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    { navigationBar: INavigationBar },
    IFetchNavigationBarByIdQueryVariablesContentful
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    { navigationBar: INavigationBar },
    IFetchNavigationBarByIdQueryVariablesContentful
  >(FetchNavigationBarByIdDocumentContentful, options)
}
